.stars {
    display: flex;
    flex-flow: row;
    
    .star {
        color: transparent;

        &.selected {
            color: var(--Yellow);
        }
    }
}