@font-face {
    font-family: "IconFont";
    src: url('IconFont.woff2?t=1721396888000') format('woff2'),
        url('IconFont.woff?t=1721396888000') format('woff'),
        url('IconFont.ttf?t=1721396888000') format('truetype');
}

.iconfont {
    font-family: "IconFont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-magnifyer:before { content: "\e900"; }
.icon-arrow-down:before { content: "\e901"; }
.icon-dots:before { content: "\e902"; }
.icon-add:before { content: "\e903"; }
.icon-magic:before { content: "\e904"; }
.icon-google:before { content: "\e905"; }
.icon-loading:before { content: "\e906"; }
.icon-star:before { content: "\e907"; }
.icon-cross:before { content: "\e908"; }


/**
 * Make by Icon Font Exporter
 * https://www.figma.com/community/plugin/1129455674275940478/Icon-Font-Exporter
 */
