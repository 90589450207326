.container {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 3em;
    background: var(--PrimaryGradient2);

    h1 {
        font-size: 4em;
        margin: 0;
    }
    div {
        font-size: 1.4em;
    }
}