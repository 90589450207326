.applicationEditor {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;

    .header {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;

        h1 {
            margin: 0;
        }
    }

    .tabs {
        display: flex;
        flex-flow: row;
        gap: 4px;
        padding: 4px 0 0 0;

        .tab {
            cursor: pointer;
            padding: 8px;

            &.active {
                color: var(--Primary70);
                font-weight: bold;
                border-bottom: 2px solid var(--Primary70);
            }

            &:hover {
                background-color: rgba(0,0,0,0.02);
            }
        }
    }

    .tabContent {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1;

        &>div {
            display: flex;
            flex-direction: column;
            gap: 24px;

            &.fill {
                flex: 1;
            }
        }

        .hidden {
            display: none;
        }
    } 

    .formGroup {
        display: flex;
        flex-flow: column;
        flex: 1;

        label {
            display: block;
            margin-bottom: 8px;
        }

        input,
        textarea,
        select {
            width: 100%;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;
        }

        textarea {
            font-family: inherit;
        }

        .notes {
            flex: 1;
        }
    }

    .error {
        color: var(--Error);
        margin: 0;
    }

    .primary-button {
        align-self: flex-end;
        margin-top: 20px;
    }

    .footer {
        display: flex;
        flex-flow: row;
        gap: 8px;
        justify-content: end;
        align-items: center;

        .spacer {
            flex: 1;
        }
    }
}