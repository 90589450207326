.applicationsFilter {
    display: flex;
    height: auto;
    flex-flow: row wrap;
    gap: 8px;
    padding: 8px;

    svg {
        color: var(--FontShaded);
    }
}