.progress {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    .spinner {
        font-size: 24px;
        animation: spin 0.9s linear infinite;
    }

    &.fullScreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--Background);
        z-index: 1000;

        .spinner {
            font-size: 7em;
            color: var(--Gray30);
        }
    
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
