@import '../../styles/variables.scss';

.container {
  display: flex;
  flex-flow: column;
  gap: 16px;

  .form {
    display: flex;
    flex-flow: column;
    gap: 16px;

    .formGroup {
      gap: 8px;

      label {
        display: block;
        margin-bottom: 0.5rem;
      }

      input {
        width: 100%;
      }
    }
  }

  button {
    width: 100%;
  }

  .error {
    color: var(--Error);
  }
}