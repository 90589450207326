:root {
  --InitGradient: linear-gradient(360deg, #9ca6b9 0%, #d7cbd4 100%);
  --PrimaryGradient1: linear-gradient(180deg, #7e8cd8 0%, #6c90d6 100%);
  --PrimaryGradient1Hover: linear-gradient(180deg, #7281d4 0%, #5f87d3 100%);
  --PrimaryGradient1Focus: linear-gradient(180deg, #6273d0 0%, #4f7bcf 100%);
  --PrimaryGradient2: linear-gradient(360deg, #6c90d6 0%, #c4baeb 100%);
  --ProgressGradient: linear-gradient(180deg, #d4a5df 0%, #ab79d2 100%);
  --SuccessGradient: linear-gradient(23.24deg, #51a477 0%, #a3c39d 100%);
  --WarningGradient: linear-gradient(180deg, #f1c17e 0%, #e6852c 100%);
  --ErrorGradient: linear-gradient(180deg, #e3938c 0%, #d96363 100%);
  --BackdropGradient: linear-gradient(180deg, #f9f9f9 37%, #f1f3f7 100%);
  --Font: #0b1e43;
  --FontShaded: #757585;
  --FontInverse: #ffffff;
  --FontInverseShaded: #e4e4e4;
  --Background: #ffffff;
  --Gray5: #f2f2f2;
  --Gray10: #e5e5e5;
  --Gray20: #cccccc;
  --Gray30: #b2b2b2;
  --Gray40: #999999;
  --Gray50: #808080;
  --Gray60: #666666;
  --Gray60: #666666;
  --Gray70: #4d4d4d;
  --Gray80: #333333;
  --Gray90: #1a1a1a;
  --Black: #000000;
  --Primary: #6c90d6;
  --Primary10: #d8e2f3;
  --Primary05: #ebf0f9;
  --Primary70: #254084;
  --PrimaryMorph: #c4baeb;
  --Success: #4a966d;
  --Error: #b82828;
  --Error5: #faeaea;
  --Error10: #f5d6d6;
  --Warning: #d96907;
  --Progress: #7e2ac1;
  --Yellow: #ffca08;
  --TextDefault-font-size: 14px;
  --TextDefault-font-family: Inter, sans-serif;
  --TextDefault-font-weight: 400;
  --Heading-font-size: 16px;
  --Heading-font-family: Inter, sans-serif;
  --Heading-font-weight: 700;
  --HeadingLg-font-size: 24px;
  --HeadingLg-font-family: Inter, sans-serif;
  --HeadingLg-font-weight: 700;
  
  

  /* App Variables */
  --breakpoint-xl: 1300px;
  --breakpoint-s: 600px;
  --mobile-padding: 16px;
  --border-radius: 8px;
  --input-height: 2.4rem;

  --card-box-shadow: inset 1px 2px 50px 0px rgba(0, 0, 255, 0.02), 
                           0px 2px 4px 0px rgba(0, 0, 255, 0.02);
}