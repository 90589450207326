.avatarWrapper {
    display: flex;
    align-items: center;
    --size: 32px;
    pointer-events: none;
    user-select: none;

    &.s {
        --size: 16px;
    }

    &.m {
        --size: 32px;
    }

    &.l {
        --size: 48px;
    }

    width: var(--size);
    height: var(--size);
    font-size: var(--size);

    .avatar {
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
    }

    .initials {
        width: var(--size);
        height: var(--size);
        line-height: 0.5em;
        padding: 0;
        margin: 0;
        border-radius: 50%;
        font-size: 0.4em;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--Font);
    }

    &.border .avatar,
    &.border .initials {
        box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.6)
    }
}