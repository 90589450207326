.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}

.sidebar {
    width: 600px;
    height: 100%;
    background: #fff;
    box-shadow: -2px 0 50px -10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    overflow-y: auto;
    z-index: 1001;
}