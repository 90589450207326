.widget {
    background: var(--BackdropGradient);
    display: flex;
    flex-flow: column;
    border-radius: var(--border-radius);
    width: 100%;
    height: 100%;
    padding: 16px;
    gap: 8px;
    box-shadow: var(--card-box-shadow);

    .title {
        font-weight: var(--Heading-font-weight);
    }

    .content {
        flex: 1;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }
}