@import '../../styles/variables.scss';

.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: var(--breakpoint-xl);
        width: 100%;
        padding: 40px var(--mobile-padding);

        .logo {
            height: 40px;
        }
    }

    .main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: var(--breakpoint-xl);
        width: 100%;
        padding: 40px var(--mobile-padding) 60px;
        height: auto;
    }
}