.userMenu {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;

  .button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;
    gap: 8px;
  }

  .dropdownMenu {
    position: absolute;
    overflow: hidden;
    top: 40px;
    right: 0;
    background-color: var(--Background);
    border: 1px solid var(--Gray10);
    border-radius: var(--border-radius);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    min-width: 200px;
    display: flex;
    flex-flow: column;
    align-items: center;

    .menuHeader {
      padding: 8px;
      background: var(--PrimaryGradient2);
      color: var(--FontInverse);
      width: 100%;
      display: flex;
      flex-flow: column;
      align-items: center;
      gap: 8px;

      .avatarWrap {
        padding: 8px;
      }

      .menuUserDetails {
        display: flex;
        flex-flow: column;
        align-items: center;
        gap: 4px;

        .menuUserName {
          font-weight: 700;
        }

        .menuUserEmail {
          font-size: 0.8rem;
        }

        margin-bottom: 8px;
      }
    }

    .dropdownItem {
      padding: 12px 16px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      width: 100%;
      text-align: center;
      text-decoration: none;

      &:hover {
        background-color: var(--Gray5);
      }
    }
  }
}