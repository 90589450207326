@import '../../styles/variables.scss';

.home {
  display: flex;
  flex-flow: column;
  flex: 1;
  gap: 60px;

  .charts {
    display: flex;
    flex-flow: row;
    gap: 40px;

    .chart {
      height: 200px;
      width: auto;
      display: flex;
      flex: 1;
    }
  }

  .applicationsWrap {
    display: flex;
    flex-flow: column;
    gap: 16px;

    .applicationsHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .applicationsFilter {
      background: var(--BackdropGradient);
      border-radius: var(--border-radius);
      box-shadow: var(--card-box-shadow);
      display: flex;
      height: auto;
      padding: 8px;
    }

    .applicationsData {
      height: auto;
      display: flex;
    }
  }
}

@media (max-width: 600px) {
  .home {
    .charts {
      flex-flow: column;
      gap: 20px;

      .chart {
        height: 150px;
      }
    }
  }
}