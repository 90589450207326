.chartWrap {
    height: 100%;
    width: 100%;
}
.tooltip {
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.05);
    background-color: var(--Background);
    padding: 8px;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 4px;

    font-size: 0.8em;

    .tooltipTitle {
        font-weight: 700;
    }

    .tooltipSubtitle {
        font-weight: 400;
    }

    .tooltipValue {
        font-weight: 400;
    }
}